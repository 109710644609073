<template>
  <div class="main">
    <el-form :label-position="'left'" label-width="80px" style="margin: 20px; width: 60%; min-width: 600px">
      <el-form-item label="标题">
        {{ homeWork != null ? homeWork.title : "" }}
      </el-form-item>
      <el-row>
        <el-col :span="12" v-if="user">
          <el-form-item label="用户">
            {{ user != null ? user.userPhone : "" }}
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="homeWork">
          <el-form-item label="id">
            {{ homeWork != null ? homeWork.id : "" }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-form-item label="课程资产信息" v-if="userOwnCourseList">
          <div v-for="(item, index) in userOwnCourseList" :key="index">
            {{ item.orderId }}, {{ item.courseName }}, 订单时间：{{ item.createTime | timeStr }}
          </div>
        </el-form-item>
      </el-row>
      <el-row>
        <el-col :span="12" v-if="homeWork">
          <el-form-item label="更新时间">
            {{ homeWork.updateTime | timeStr }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12" v-if="homeWork">
          <el-form-item label="内容">
            <div>{{ homeWork.workText }}</div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12" v-if="homeWork">
          <el-form-item label="用户上传">
            <aidaUploader
              v-model="homeWork.userAttach"
              :readonly="true"
              :bizType="bizType"
              :isSingleFile="isSingleFile"
              :maxSize="100"
            >
            </aidaUploader>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="homeWork && homeWork.userAttach">
        <div style="text-align:left;margin:10px 0px" v-for="(item, index) in homeWork.userAttach" :key="index">
          {{ item.url }}
        </div>
      </el-row>
      <el-row>
        <el-col :span="24" v-if="homeWork">
          <el-form-item label="批改">
            <div>{{ homeWork.teacherText }}</div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12" v-if="homeWork">
          <el-form-item label="老师上传">
            <aidaUploader
              v-model="homeWork.teacherAttach"
              :readonly="true"
              :bizType="bizType"
              :isSingleFile="isSingleFile"
              :maxSize="100"
            >
            </aidaUploader>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import msgLogger from "@/util/msgLogger";
import { userHomeWorkApi } from "@/api/userHomeWorkApi";
import dayjs from "dayjs";
import aidaUploader from "@/component/aidaUploader.vue";

export default {
  components: {
    aidaUploader,
  },
  data() {
    return {
      isSingleFile: false,
      user: null,
      course: null,
      homeWork: null,
      userOwnCourseList: null,
      bizType: "user_home_work",
    };
  },
  methods: {
    updateUserAttach(retList) {
      console.log("update user attach", retList);
    },
    initData(id) {
      userHomeWorkApi.getUserHomeWork(id).then((ret) => {
        let { code, data } = ret;
        if (code == 0 && data) {
          this.homeWork = data;
          console.log("#1", this.homeWork);
          userHomeWorkApi.getUserHomeWorkExtraInfo(id).then((ret) => {
            console.log("extra info", ret);
            let { code, data } = ret;
            if (code == 0) {
              this.user = data.user;
              this.course = data.course;
              this.userOwnCourseList = data.userOwnCourseList;
              console.log("#2", this.user, this.course, this.userOwnCourseList);
            }
          });
        }
      });
    },
  },
  mounted() {
    if (this.$route && this.$route.query && this.$route.query.id) {
      const id = this.$route.query.id;
      this.initData(id);
    }
  },
  filters: {
    timeStr: function(value) {
      let day = dayjs(Number(value));
      return day.format("YYYY-MM-DD hh:mm");
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  .teacher-text {
    width: 80%;
  }
  .button-row {
    margin: 20px;
  }
}
</style>
